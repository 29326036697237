import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

export default function CoinDetails({ coin, show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Coin Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}><strong>Name:</strong></Col>
                    <Col md={6}>{coin.name}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Short Code:</strong></Col>
                    <Col md={6}>{coin.shortCode}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Price:</strong></Col>
                    <Col md={6}>${coin.price}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Description:</strong></Col>
                    <Col md={6}>{coin.description}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Market Cap:</strong></Col>
                    <Col md={6}>${coin.marketCap}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>24h Volume:</strong></Col>
                    <Col md={6}>${coin.volume24h}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Circulating Supply:</strong></Col>
                    <Col md={6}>{coin.circulatingSupply}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Total Supply:</strong></Col>
                    <Col md={6}>{coin.totalSupply}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Max Supply:</strong></Col>
                    <Col md={6}>{coin.maxSupply}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Launch Date:</strong></Col>
                    <Col md={6}>{new Date(coin.launchDate).toLocaleDateString()}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Website:</strong></Col>
                    <Col md={6}><a href={coin.website} target="_blank" rel="noopener noreferrer">{coin.website}</a></Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Whitepaper:</strong></Col>
                    <Col md={6}><a href={coin.whitepaper} target="_blank" rel="noopener noreferrer">{coin.whitepaper}</a></Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Decimal:</strong></Col>
                    <Col md={6}>{coin.decimal}</Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Status:</strong></Col>
                    <Col md={6} className={coin.isActive ? 'text-success' : 'text-danger'}>
                        {coin.isActive ? 'Active' : 'Inactive'}
                    </Col>
                </Row>
                <Row>
                    <Col md={6}><strong>Icon:</strong></Col>
                    <Col md={6}><img src={coin.icon} alt={coin.name} width={50} /></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
