import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft } from "react-icons/fa";

const ResetPassword = () => {
    const Params = useParams()
    const token = Params.token
    const navigate = useNavigate()
    const [ErrorMessage, setErrorMessage] = useState();
    const [showPasswordBlock, setShowPasswordBlock] = useState(false);
    const [SuccessMessage, setSuccessMessage] = useState();
    const [resetPasswordData, setResetPasswordData] = useState({
        token: token,
        password: "",
        repeatPassword: ""
    });
    const resetPasswordToken = {
        token: token
    }
    const { password, repeatPassword } = resetPasswordData
    const onInputChange = e => {
        setResetPasswordData({ ...resetPasswordData, [e.target.name]: e.target.value });
    };
    const SubmitHandler = async (e) => {
        e.preventDefault();
        if (password === repeatPassword) {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/reset-password`, resetPasswordData, { withCredentials: true }).catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    if (error.response.status === 401 || 500) {
                        setErrorMessage("Invalid password reset link!")
                    }
                    console.log(error.response.headers);
                }
            });
            if (response.data) {
                setSuccessMessage("Password reset successfully! Redirecting to Login page... ")
                setTimeout(() => {
                    navigate('/signin')
                }, 3000)
            }
        } else {
            setErrorMessage("Both Passwords Are Not Matching")
        }
    }
    const checkTokenValid = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/reset-password-token`, resetPasswordToken, { withCredentials: true }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                if (error.response.status === 401 || 500) {
                    setErrorMessage("Invalid password reset link!")
                }
                console.log(error.response.headers);
            }
        });
        if (response.data) {
            setShowPasswordBlock(true)
        }
    }
    useEffect(() => {
        checkTokenValid()
        // eslint-disable-next-line
    }, [token]);
    return (
        <section className="account padding-top padding-bottom sec-bg-color2">
            <div className="container">
                <div className="account__wrapper">
                    <div className="row g-4">
                        <div className="col-lg-12">
                            <div className="account__content account__content--style1">
                                <div className="account__header">
                                    <h2>Reset Your Password</h2>
                                    {ErrorMessage && <div className="alert alert-danger" role="alert">{ErrorMessage} </div>}
                                    {SuccessMessage && <div className="alert alert-success" role="alert">{SuccessMessage} </div>}
                                </div>
                                <form onSubmit={SubmitHandler} className={`${showPasswordBlock ? '' : 'd-none'}`}>
                                    <div className="row mb-3">
                                        <label htmlFor="password" className="col-sm-4 col-md-4 col-form-label">New Password</label>
                                        <div className="col-sm-6 col-md-8">
                                            <input type="password" name='password' value={password} onChange={e => onInputChange(e)} className="form-control" id="password" placeholder="Enter New Password" required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="repeatPassword" className="col-sm-4 col-md-4 col-form-label">Repeat Password</label>
                                        <div className="col-sm-6 col-md-8">
                                            <input type="password" name='repeatPassword' value={repeatPassword} onChange={e => onInputChange(e)} className="form-control" id="repeatPassword" placeholder="Repeat New Password" required />
                                        </div>
                                    </div>
                                    <div className="mb-3 float-end">
                                        <button className='btn btn-primary px-5 me-2' type='submit'>Submit</button>
                                    </div>
                                </form>
                                <div className="account__switch">
                                    <p>
                                        <Link to="/signin" className="style2"> <FaArrowLeft />
                                            Back to <span>Login</span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="account__shape">
                <span className="account__shape-item account__shape-item--1">
                    <img src="/assets/images/others/4.png" alt="shape-icon" /></span>
            </div>
        </section>
    );
}

export default ResetPassword;
