import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaTwitter, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import TradeBuySell from '../Trades/TradeBuySell';

const SocialLinks = () => (
    <ul className="social">
        {[FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaTwitter].map((Icon, index) => (
            <li className="social__item" key={index}>
                { //eslint-disable-next-line
                    <a href="/" className={`social__link social__link--style1 ${Icon === FaFacebookF ? 'active' : ''}`}>
                        <Icon />
                    </a>
                }
            </li>
        ))}
    </ul>
);

export default function Banner() {
    const bgImage = '/assets/images/home/bg.png';

    return (
        <section className="banner banner--style1" style={{ backgroundImage: 'url(' + bgImage + ')' }}>
            <div className="container">
                <div className="banner__wrapper">
                    <div className="row gy-5 gx-4 d-flex align-items-center">
                        <div className="col-lg-6 col-md-7">
                            <div className="banner__content">
                                <div className="banner__content-coin">
                                    <img src="/assets/images/home/3.png" alt="coin icon" />
                                </div>
                                <h1 className="banner__content-heading">
                                    Premium Cryptocurrency <span>Exchange</span> for Institutions and Traders
                                </h1>
                                <p className="banner__content-moto">
                                    Tailored for institutions
                                    <br />Preferred by everyday traders
                                </p>
                                <div className="banner__btn-group btn-group">
                                    <Link to="/signup" className="trk-btn trk-btn--primary trk-btn--arrow">Get Started <FaArrowRight /></Link>
                                    <Link to="/contact-us" className="trk-btn trk-btn--outline22"> Contact us</Link>
                                </div>
                                <div className="banner__content-social">
                                    <p>Follow Us</p>
                                    <SocialLinks />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-6">
                            <div className="d-flex justify-content-center justify-content-lg-end">
                                <div className="trade-options">
                                    <div className="card rounded-xl w-100">
                                        <div className="card-body">
                                            <TradeBuySell />
                                            <div className='text-center mb-2'>
                                                <Link to="/buy-and-sell" className='link'> Visit Buy & Sell page</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner__shape">
                <span className="banner__shape-item banner__shape-item--1">
                    <img src="/assets/images/home/4.png" alt="shape icon" /></span>
            </div>
        </section>
    );
}
