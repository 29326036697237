import React from 'react';

export default function About() {
    return (
        <section className="about about--style1">
            <div className="container">
                <div className="about__wrapper">
                    <div className="row gx-5 gy-4 gy-sm-0 align-items-center">
                        <div className="col-sm-12 col-md-6 col-lg-7">
                            <div className="about__thumb pe-lg-5">
                                <div className="about__thumb-inner">
                                    <div className="about__thumb-image floating-content">
                                        <video
                                            autoPlay
                                            loop
                                            muted
                                            playsInline
                                            className="appl1-hdvd-xx w-100"
                                        >
                                            <source src="https://uploads-ssl.webflow.com/61adfb51550e7ed9348c43a9/6230a274eb639330c8ce90b8_CoinsRotateNew3-transcode.mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-5">
                            <div className="about__content">
                                <div className="about__content-inner">
                                    <h2>
                                        More than a crypto <span>exchange</span>
                                    </h2>
                                    <p className="mb-0">
                                        Whether you are a trader, asset manager or importer/exporter - we have just the solution
                                        for you. Trade crypto or fiat, plug-in to our deep liquidity or send value across the globe with peace
                                        of mind.
                                    </p>
                                    <div className="d-flex">
                                        <button className="trk-btn trk-btn--border btn-rounded trk-btn--primary">Trade</button>
                                        <button className="trk-btn trk-btn--outline22 ms-2">Institution</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
