import React from 'react'

export default function InstitutionalProducts() {
    const containerStyle = {
        minWidth: '637px',
        height: '600px',
        position: 'relative',
        width: '100%',
    };

    const coinStyle = (top, right, bottom, left, width, height) => ({
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5%',
        backgroundColor: 'white',
        boxShadow: '0 1rem 3rem rgba(0, 0, 0, 0.175)',
        top: top || 'auto',
        right: right || 'auto',
        bottom: bottom || 'auto',
        left: left || 'auto',
        width: width,
        height: height,
    });

    const imgStyle = (width) => ({
        width: width,
    });

    return (
        <div className="container">
            <div className="row align-items-center">
                <div className="col-sm-12 col-md-6 p-4">
                    <h1 className="font-weight-bold mb-3 text-black">Institutional products</h1>
                    <p className="mb-4">Xenly Exchange is tailored specifically for institutional users. It boasts state-of-the-art institutional-grade security and is engineered to handle high-volume trades with instant execution and no slippage. With its powerful liquidity engine and the most comprehensive and flexible on/off-ramp ecosystems, Xenly is the perfect platform for high-volume traders looking to optimize their crypto investments.</p>
                    <div className="">
                        <button className='btn btn-primary rounded-30 me-2 btn-lg'>Get Started</button>
                        <button className="btn btn-outline-secondary btn-lg rounded-30">Contact us</button>
                    </div>
                </div>
                <div className="col-6">
                    <div style={containerStyle} className='d-none d-md-block'>
                        <div style={coinStyle('161px', '77px', null, null, '110px', '110px')} className='animate-floating-busd'>
                            <picture>
                                <source srcSet="/assets/images/coins/SOL.svg" />
                                <img alt="SOL" src="/assets/images/coins/SOL.svg" style={imgStyle('50px')} />
                            </picture>
                        </div>
                        <div style={coinStyle('240px', '219px', null, null, '90px', '90px')}>
                            <picture>
                                <source srcSet="/assets/images/coins/ADA.svg" />
                                <img alt="ADA" src="/assets/images/coins/ADA.svg" style={imgStyle('50px')} />
                            </picture>
                        </div>
                        <div style={coinStyle(null, '49px', '110px', null, '150px', '150px')} className='animate-floating-eth'>
                            <picture>
                                <source srcSet="/assets/images/coins/eth.svg" />
                                <img alt="ETH" src="/assets/images/coins/eth.svg" style={imgStyle('50px')} />
                            </picture>
                        </div>
                        <div style={coinStyle('98px', null, null, '119px', '90px', '90px')}>
                            <picture>
                                <source srcSet="/assets/images/coins/xrp.svg" />
                                <img alt="XRP" src="/assets/images/coins/xrp.svg" style={imgStyle('50px')} />
                            </picture>
                        </div>
                        <div style={coinStyle(null, null, '115px', '80px', '90px', '90px')} className='animate-floating-bnb'>
                            <picture>
                                <source srcSet="/assets/images/coins/MATIC.svg" />
                                <img alt="MATIC" src="/assets/images/coins/MATIC.svg" style={imgStyle('40px')} />
                            </picture>
                        </div>
                        <div style={coinStyle(null, '250px', '45px', null, '156px', '156px')} className='animate-floating-zarp'>
                            <picture>
                                <source srcSet="/assets/images/coins/zarp.svg" />
                                <img alt="ZARP" src="/assets/images/coins/zarp.svg" style={imgStyle('90px')} />
                            </picture>
                        </div>
                        <div style={coinStyle('226px', null, null, '11px', '130px', '130px')} className='animate-floating-usdt'>
                            <picture>
                                <source srcSet="/assets/images/coins/usdt.svg" />
                                <img alt="USDT" src="/assets/images/coins/usdt.svg" style={imgStyle('90px')} />
                            </picture>
                        </div>
                        <div style={coinStyle('229px', null, null, '169px', '140px', '140px')} className='animate-floating-usdc'>
                            <picture>
                                <source srcSet="/assets/images/coins/usdc.svg" />
                                <img alt="USDC" src="/assets/images/coins/usdc.svg" style={imgStyle('70px')} />
                            </picture>
                        </div>
                        <div style={coinStyle('37px', '216px', null, null, '166px', '166px')} className='animate-floating-btc'>
                            <picture>
                                <source srcSet="/assets/images/coins/btc.svg" />
                                <img alt="BTC" src="/assets/images/coins/btc.svg" style={imgStyle('70px')} />
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
