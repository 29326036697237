import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
// Import required modules
import { Autoplay } from 'swiper/modules';

export default function Partners() {
    return (
        <div className="partner partner--gradient">
            <div className="container">
                <div className="partner__wrapper">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={5}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        className="partner__slider"
                    >
                        <SwiperSlide>
                            <div className="partner__item">
                                <div className="partner__item-inner">
                                    <img src="/assets/images/partner/1.png" alt="partner logo" className="dark" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partner__item">
                                <div className="partner__item-inner">
                                    <img src="/assets/images/partner/2.png" alt="partner logo" className="dark" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partner__item">
                                <div className="partner__item-inner">
                                    <img src="/assets/images/partner/3.png" alt="partner logo" className="dark" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partner__item">
                                <div className="partner__item-inner">
                                    <img src="/assets/images/partner/4.png" alt="partner logo" className="dark" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partner__item">
                                <div className="partner__item-inner">
                                    <img src="/assets/images/partner/5.png" alt="partner logo" className="dark" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
