import React from 'react';
import { Accordion, Container, Row, Col } from 'react-bootstrap';

export default function Faqs() {
    return (
        <section className="faq padding-top padding-bottom of-hidden">
            <div className="section-header section-header--max65">
                <h2 className="mb-15 mt-minus-5">
                    <span>Frequently</span> Asked Questions
                </h2>
                <p>
                    Hey there! Got questions? We've got answers. Check out our FAQ page for all the deets. Still not satisfied? Hit us up.
                </p>
            </div>
            <Container>
                <div className="faq__wrapper">
                    <Row className="g-5 align-items-center justify-content-between">
                        <Col lg={6}>
                            <Accordion defaultActiveKey="0" id="faqAccordion1" data-aos="fade-right" data-aos-duration="1000">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What does this tool do?</Accordion.Header>
                                    <Accordion.Body>
                                        Online trading’s primary advantages are that it allows you to manage your trades at your convenience.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What are the disadvantages of online trading?</Accordion.Header>
                                    <Accordion.Body>
                                        You don’t need to worry, the interface is user-friendly. Anyone can use it smoothly. Our user manual will help you to solve your problem.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Is online trading safe?</Accordion.Header>
                                    <Accordion.Body>
                                        Online trading’s primary advantages are that it allows you to manage your trades at your convenience.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What is online trading, and how does it work?</Accordion.Header>
                                    <Accordion.Body>
                                        Online trading’s primary advantages are that it allows you to manage your trades at your convenience.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Which app is best for online trading?</Accordion.Header>
                                    <Accordion.Body>
                                        Online trading’s primary advantages are that it allows you to manage your trades at your convenience.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>How to create a trading account?</Accordion.Header>
                                    <Accordion.Body>
                                        Online trading’s primary advantages are that it allows you to manage your trades at your convenience.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col lg={6}>
                            <div className="faq__thumb faq__thumb--style1" data-aos="fade-left" data-aos-duration="1000">
                                <img className="dark" src="/assets/images/others/1.png" alt="faq-thumb" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <div className="faq__shape faq__shape--style1">
                <span className="faq__shape-item faq__shape-item--1">
                    <img src="/assets/images/others/2.png" alt="shape-icon" />
                </span>
            </div>
        </section>
    );
}
