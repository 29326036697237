import React from 'react'
import { FaUserPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function HowTo() {
    return (
        <div className='container py-5'>
            <div className="row">
                <div className="col">
                    <h2 className="my-5">How to get started</h2>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-center step-1">
                        <div className="card-body">
                            <FaUserPlus size={24} className="mb-3 text-white" />
                            <h5 className="card-title">Create account</h5>
                            <p className="card-text">Sign in to Xenly to register a new profile.</p>
                            <Link to="/signup" className="btn btn-warning">Register now</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-md-9 mb-4">
                    <div className="rest-3-step">
                        <div className="card text-center">
                            <div className="card-body">
                                <div className="step-number">2</div>
                                <h5 className="card-title text-black">Verify your identity</h5>
                                <p className="card-text">Spend less than five minutes completing the verification process.</p>
                                <h5 className='text-muted'>Create your account first</h5>
                            </div>
                        </div>
                        <div className="card text-center">
                            <div className="card-body">
                                <div className="step-number">3</div>
                                <h5 className="card-title text-black">Buy or deposit crypto</h5>
                                <p className="card-text">Add funds to your Xenly account to access crypto services.</p>
                                <h5 className='text-muted'>Verify your identity first</h5>
                            </div>
                        </div>
                        <div className="card text-center">
                            <div className="card-body">
                                <div className="step-number">4</div>
                                <h5 className="card-title text-black">Start your journey</h5>
                                <p className="card-text">Explore crypto opportunities within the Xenly ecosystem.</p>
                                <h5 className='text-muted'> Buy or deposit crypto first</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
