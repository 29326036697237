import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

export default function VerifyEmail() {
    const [verificationStatus, setVerificationStatus] = useState('Verifying...');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Extract token and email from URL
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const email = queryParams.get('email');

        const verifyEmail = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/verify-email`, {
                    params: { token, email }
                });

                if (response.status === 200) {
                    setVerificationStatus('Email successfully verified! Redirecting to login...');
                    setTimeout(() => {
                        navigate('/signin');
                    }, 3000);
                }
            } catch (error) {
                setVerificationStatus('Verification failed or token expired. Please try again.');
            }
        };

        if (token && email) {
            verifyEmail();
        } else {
            setVerificationStatus('Invalid verification link.');
        }
    }, [location.search, navigate]);

    return (
        <div className="page">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="verify-email">
                            <h2>{verificationStatus}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
