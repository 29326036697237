import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import axios from 'axios'

export default function Signup() {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        terms: true // Default to false so user has to explicitly check it
    });
    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const validateForm = () => {
        let formErrors = {};

        if (!formData.FirstName.match(/^[A-Za-z\s]+$/)) {
            formErrors.FirstName = "Name must only contain alphabets and spaces.";
        }
        if (!formData.LastName.match(/^[A-Za-z\s]+$/)) {
            formErrors.LastName = "Name must only contain alphabets and spaces.";
        }

        if (!formData.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            formErrors.email = "Invalid email format.";
        }

        if (formData.password.length < 6) {
            formErrors.password = "Password must be at least 6 characters long.";
        }

        if (formData.password !== formData.confirmPassword) {
            formErrors.confirmPassword = "Passwords do not match.";
        }

        if (!formData.terms) {
            formErrors.terms = "You must agree to the Terms and Conditions.";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    }

    const handleInputChange = (e) => {
        const { id, value, type, checked } = e.target;

        setFormData({
            ...formData,
            [id]: type === 'checkbox' ? checked : value // Handle checkbox input
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/users`, formData, {
                    withCredentials: true
                });

                if (response.status === 200) {
                    setSuccessMessage('We have sent you an email for the verification process. Please keep a look out for it from support@xenly.com');
                    setTimeout(() => {
                        navigate('/signin');
                    }, 3000);
                }
            } catch (error) {
                setApiError(error.response?.data?.message || 'Something went wrong. Please try again.');
            }
        }
    }

    return (
        <section className="account padding-top padding-bottom sec-bg-color2">
            <div className="container">
                <div className="account__wrapper">
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="account__content account__content--style1">
                                <div className="account__header">
                                    <h2>Create Your Account</h2>
                                    <p>
                                        Hey there! Ready to join the party? We just need a few details
                                        from you to get started. Let's do this!
                                    </p>
                                </div>

                                {apiError && <div className="alert alert-danger">{apiError}</div>}
                                {successMessage && <div className="alert alert-success">{successMessage}</div>}

                                <form className="account__form needs-validation" onSubmit={handleSubmit} noValidate>
                                    <div className="row g-4">
                                        {/* Name Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="FirstName" className="form-label">First Name</label>
                                                <input
                                                    className={`form-control ${errors.FirstName ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    id="FirstName"
                                                    value={formData.FirstName}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. John"
                                                />
                                                {errors.FirstName && <div className="invalid-feedback">{errors.FirstName}</div>}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="LastName" className="form-label">Last Name</label>
                                                <input
                                                    className={`form-control ${errors.LastName ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    id="LastName"
                                                    value={formData.LastName}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. Doe"
                                                />
                                                {errors.LastName && <div className="invalid-feedback">{errors.LastName}</div>}
                                            </div>
                                        </div>

                                        {/* Email Field */}
                                        <div className="col-12">
                                            <div>
                                                <label htmlFor="email" className="form-label">Email</label>
                                                <input
                                                    type="email"
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter your email"
                                                    required
                                                />
                                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                            </div>
                                        </div>

                                        {/* Password Field */}
                                        <div className="col-12">
                                            <div className="form-pass">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                    id="password"
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    placeholder="Password"
                                                    required
                                                />
                                                <button type="button" className="form-pass__toggle" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                </button>
                                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                            </div>
                                        </div>

                                        {/* Confirm Password Field */}
                                        <div className="col-12">
                                            <div className="form-pass">
                                                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                                    id="confirmPassword"
                                                    value={formData.confirmPassword}
                                                    onChange={handleInputChange}
                                                    placeholder="Re-type password"
                                                    required
                                                />
                                                <button type="button" className="form-pass__toggle" onClick={toggleConfirmPasswordVisibility}>
                                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                </button>
                                                {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                            </div>
                                        </div>

                                        {/* Terms and Conditions Checkbox */}
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input
                                                    className={`form-check-input ${errors.terms ? 'is-invalid' : ''}`}
                                                    type="checkbox"
                                                    id="terms"
                                                    checked={formData.terms}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="form-check-label" htmlFor="terms">
                                                    By creating an account, you declare that you have read and agree to the <a href="/terms-and-conditions">Terms and Conditions</a>.
                                                </label>
                                                {errors.terms && <div className="invalid-feedback">{errors.terms}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="trk-btn trk-btn--border trk-btn--primary d-block mt-4">Sign Up</button>
                                </form>
                                <div className="account__switch">
                                    <p>Already have an account?<Link to="/signin">Login</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="account__shape">
                <span className="account__shape-item account__shape-item--1">
                    <img src="/assets/images/others/4.png" alt="shape-icon" />
                </span>
            </div>
        </section>
    )
}
