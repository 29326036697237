import React from 'react'

export default function Ramps() {
    return (
        <div className="bg-white">
            <div className="container">
                <div className='row align-items-center'>
                    <div className="col-12 col-md-6 py-5">
                        <h2>On/Off Ramps</h2>
                        <p>Easily transition between crypto and fiat using our extensive network of established on and off ramps. You can meet all your trading needs in one place.</p>
                        <p>Utilize our comprehensive tech stack to provide your clients to deep cryptocurrency liquidity and automate all fiat deposits and withdrawals through our reliable banking infrastructure. Our platform's flexibility enables you to create tailored solutions across various markets for your clients.</p>
                        <p>Seamlessly connect the worlds of traditional Finance and the Crypto World</p>
                        <div className="d-flex">
                            <button className='btn btn-secondary rounded-30 me-2 btn-lg'>Get Started</button>
                            <button className="btn btn-outline-secondary btn-lg rounded-30">Contact us</button>
                        </div>
                    </div>
                    <div className="col-6 py-5 d-none d-md-flex align-items-center justify-content-center">
                        <img src="/assets/images/on-off-ramp.svg" alt="ramp" />
                    </div>
                </div>
            </div>
        </div>
    )
}
