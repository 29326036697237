import React from 'react'

export default function OTCHighVolume() {
    return (
        <div className='bg-white'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 py-5">
                        <h2 className="mb-3">Premier High-Volume Trading Solution</h2>
                        <p>Designed for those seeking extensive asset support, zero slippage, and immediate execution for orders of any size.</p>
                        <p>Enjoy a private trading experience outside of public order books.</p>
                        <p>The minimum order size is R300,000, with possible exceptions available upon request.</p>
                        <div className="d-flex">
                            <button className='btn btn-secondary rounded-30 me-2 btn-lg'>Get Started</button>
                            <button className="btn btn-outline-secondary btn-lg rounded-30">Contact us</button>
                        </div>
                    </div>
                    <div className="col-6 py-5 d-none d-md-flex align-items-center justify-content-center">
                        <img src="/assets/images/otc-high-value.png" alt="otc high velue" style={{ maxHeight: '300px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
