import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { setCoinData, setTradePairs } from '../../redux/coinSlice';

export default function TradePairs() {
    const dispatch = useDispatch();
    const coins = useSelector(state => state.coin.coins);
    const tradePairs = useSelector(state => state.coin.tradePairs);
    const [pairData, setPairData] = useState({
        coin1: '',
        coin2: '',
        default: false
    });
    const [filteredCoins, setFilteredCoins] = useState([]);

    useEffect(() => {
        if (!coins.length) {
            fetchCoins();
        }
        fetchTradePairs();
    }, [coins, dispatch]);

    useEffect(() => {
        // Automatically filter coin2 options when coin1 is selected
        setFilteredCoins(coins.filter(coin => coin.shortCode !== pairData.coin1));
    }, [pairData.coin1, coins]);

    const fetchCoins = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/coins`, { withCredentials: true });
            dispatch(setCoinData(response.data));
        } catch (error) {
            console.error('Failed to fetch coins:', error);
        }
    };

    const fetchTradePairs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tradePairs`, { withCredentials: true });
            dispatch(setTradePairs(response.data));
        } catch (error) {
            console.error('Failed to fetch trade pairs:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setPairData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/tradePairs`, pairData, { withCredentials: true });
            if (response.data) {
                dispatch(setTradePairs([...tradePairs, response.data]));
                setPairData({ coin1: '', coin2: '', default: false }); // Reset form
                fetchTradePairs(); // Refresh the list
            }
        } catch (error) {
            console.error('Error creating trade pair:', error);
        }
    };

    const columns = [
        {
            name: 'Coin 1',
            selector: row => row.coin1.shortCode,
            sortable: true
        },
        {
            name: 'Coin 2',
            selector: row => row.coin2.shortCode,
            sortable: true
        },
        {
            name: 'Default',
            cell: row => row.default ? 'Yes' : 'No',
            sortable: true
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Link to={`/edit-trade-pair/${row._id}`} className="btn btn-info btn-sm"><FiEdit /></Link>
                    <button onClick={() => deleteTradePair(row._id)} className="btn btn-danger btn-sm"><FiTrash /></button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const deleteTradePair = async (pairId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/tradePairs/${pairId}`, { withCredentials: true });
            fetchTradePairs(); // Refresh the list after deletion
        } catch (error) {
            console.error('Failed to delete trade pair:', error);
        }
    };

    return (
        <div className='page'>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header">
                                <h3>Create Trade Pairs</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="coin1" className="form-label">Coin 1</label>
                                        <select
                                            className="form-control"
                                            id="coin1"
                                            name="coin1"
                                            value={pairData.coin1}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Coin</option>
                                            {coins.map(coin => (
                                                <option key={coin._id} value={coin._id}>{coin.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="coin2" className="form-label">Coin 2</label>
                                        <select
                                            className="form-control"
                                            id="coin2"
                                            name="coin2"
                                            value={pairData.coin2}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Coin</option>
                                            {filteredCoins.map(coin => (
                                                <option key={coin._id} value={coin._id}>{coin.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="default"
                                            name="default"
                                            checked={pairData.default}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="default">Default Pair</label>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Create Pair</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <DataTable
                            columns={columns}
                            data={tradePairs}
                            pagination
                            persistTableHead
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}