import React from 'react'
import { useSelector } from 'react-redux';

export default function Dashboard() {
    const bgImage = '/assets/images/welcome_banner.webp';
    const userDetails = useSelector((state) => state.user.userDetails);
    return (
        <div className="page">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="bg_welcome" style={{ backgroundImage: 'url(' + bgImage + ')' }}>
                            <div className="welcome_content">
                                <h1 className='text-capitalize'>Welcome to XENLY</h1>
                                <p><strong>Enjoy unrestricted access to the world of cryptocurrency. But first, you need to verify your account. Let's get you set up and ready to trade:</strong></p>
                                <button className='btn btn-success px-4'>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-5">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header">
                                <strong>Verification Level</strong>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-2">
                                        {userDetails?.avatar && <img src={userDetails?.avatar} alt={userDetails?.name} className='rounded-circle shadow-4-strong' />}
                                    </div>
                                    <div className="col-10">
                                        <h5><strong>{userDetails?.name}</strong></h5>
                                        <h5><strong>{userDetails?.email}</strong></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header">
                                <strong>Announcements</strong>
                            </div>
                            <div className="card-body">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
