import React from 'react'
import { Accordion } from 'react-bootstrap';

export default function InterestAccount() {
    return (
        <div className="page">
            <div className='container'>
                <div className="row py-5">
                    <div className="col-12 col-md-6">
                        <h2 className="mb-3">High Yield Interest Account</h2>
                        <p>A 100% market-neutral yield product for your idle crypto. Future-proof your savings with inflation-busting interest on dollar stablecoins and other popular cryptocurrencies.</p>
                        <p>Get access to annual percentage rates of 8.5% and more.</p>
                        <p>Simply deposit your crypto into the account and earn an annualised interest rate which is paid out daily!</p>
                        <div className="d-flex mb-3">
                            <button className='btn btn-secondary rounded-30 me-2 btn-lg'>Get Started</button>
                            <button className="btn btn-outline-secondary btn-lg rounded-30">Contact us</button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header py-3 d-flex justify-content-center">
                                <h5 className='mb-0'>Interest Accounts are available for the following assets</h5>
                            </div>
                            <div className="card-body">
                                <Accordion className='d-sm-block d-md-none'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className="coin d-flex align-items-center">
                                                    <img alt="btc" className="w-35 h-35" src="/assets/images/coins/btc.svg" />
                                                    <p className="md-text-14 font-sans text-9 text-dark fw-semibold pt-2 ms-2">BTC</p>
                                                </div>
                                                <div className="interest text-end">
                                                    <p className="mb-0">Earn up to</p>
                                                    <p className="mb-0">2% Interest</p>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance</p>
                                                    <p className="mb-0 fs-6 fw-bold">0.01 BTC</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$30,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$60,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$600,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$1,200,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$3,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$6,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className="coin d-flex align-items-center">
                                                    <img alt="eth" className="w-35 h-35 me-2" src="/assets/images/coins/eth.svg" />
                                                    <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">ETH</p>
                                                </div>
                                                <div className="interest text-end">
                                                    <p className="mb-0">Earn up to</p>
                                                    <p className="mb-0">2% Interest</p>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance</p>
                                                    <p className="mb-0 fs-6 fw-bold">0.01 BTC</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$30,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$60,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$600,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$1,200,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$3,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$6,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className="coin d-flex align-items-center">
                                                    <img alt="usdc" className="w-35 h-35 me-2" src="/assets/images/coins/usdc.svg" />
                                                    <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">USDC</p>
                                                </div>
                                                <div className="interest text-end">
                                                    <p className="mb-0">Earn up to</p>
                                                    <p className="mb-0">2% Interest</p>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance</p>
                                                    <p className="mb-0 fs-6 fw-bold">0.01 BTC</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$30,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$60,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$600,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$1,200,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$3,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$6,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className="coin d-flex align-items-center">
                                                    <img alt="usdt" className="w-35 h-35 me-2" src="/assets/images/coins/usdt.svg" />
                                                    <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">USDT</p>
                                                </div>
                                                <div className="interest text-end">
                                                    <p className="mb-0">Earn up to</p>
                                                    <p className="mb-0">2% Interest</p>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance</p>
                                                    <p className="mb-0 fs-6 fw-bold">0.01 BTC</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$30,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$60,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$600,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$1,200,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$3,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$6,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className="coin d-flex align-items-center">
                                                    <img alt="xrp" className="w-35 h-35 me-2" src="/assets/images/coins/xrp.svg" />
                                                    <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">XRP</p>
                                                </div>
                                                <div className="interest text-end">
                                                    <p className="mb-0">Earn up to</p>
                                                    <p className="mb-0">2% Interest</p>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance</p>
                                                    <p className="mb-0 fs-6 fw-bold">0.01 BTC</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$30,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$60,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$600,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$1,200,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$3,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$6,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className="coin d-flex align-items-center">
                                                    <img alt="zarp" className="w-35 h-35 me-2" src="/assets/images/coins/zarp.svg" />
                                                    <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">ZARP</p>
                                                </div>
                                                <div className="interest text-end">
                                                    <p className="mb-0">Earn up to</p>
                                                    <p className="mb-0">2% Interest</p>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance</p>
                                                    <p className="mb-0 fs-6 fw-bold">0.01 BTC</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">Minimum Balance +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$30,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">1.5 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$60,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$600,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$1,200,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$3,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <p className="mb-0 fs-6 fw-normal">$6,000,000 +</p>
                                                    <p className="mb-0 fs-6 fw-bold">2.0 %</p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="w-100">
                                    <table className="table w-100 d-none d-lg-block">
                                        <thead>
                                            <tr className="border-top border-bottom border-2">
                                                <th className="py-3" style={{ width: '30%' }}></th> {/* Empty column */}
                                                <th className="py-3" style={{ width: '12%' }}>
                                                    <div>
                                                        <img alt="btc" className="w-35 h-35" src="/assets/images/coins/btc.svg" />
                                                        <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">BTC</p>
                                                    </div>
                                                </th>
                                                <th className="py-3" style={{ width: '12%' }}>
                                                    <div>
                                                        <img alt="eth" className="w-35 h-35" src="/assets/images/coins/eth.svg" />
                                                        <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">ETH</p>
                                                    </div>
                                                </th>
                                                <th className="py-3" style={{ width: '12%' }}>
                                                    <div>
                                                        <img alt="usdc" className="w-35 h-35" src="/assets/images/coins/usdc.svg" />
                                                        <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">USDC</p>
                                                    </div>
                                                </th>
                                                <th className="py-3" style={{ width: '12%' }}>
                                                    <div>
                                                        <img alt="usdt" className="w-35 h-35" src="/assets/images/coins/usdt.svg" />
                                                        <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">USDT</p>
                                                    </div>
                                                </th>
                                                <th className="py-3" style={{ width: '12%' }}>
                                                    <div>
                                                        <img alt="xrp" className="w-35 h-35" src="/assets/images/coins/xrp.svg" />
                                                        <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">XRP</p>
                                                    </div>
                                                </th>
                                                <th className="py-3" style={{ width: '12%' }}>
                                                    <div>
                                                        <img alt="zarp" className="w-35 h-35" src="/assets/images/coins/zarp.svg" />
                                                        <p className="mb-0 md-text-14 font-sans text-9 text-dark fw-semibold pt-2 w-100">ZARP</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-top border-bottom border-2">
                                                <td className="text-left py-1 fw-medium">Minimum Balance +</td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>1.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>1.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>1.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                            </tr>
                                            <tr className="border-top border-bottom border-2">
                                                <td className="text-left py-1 fw-medium">$30,000 +</td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>1.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>1.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>1.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                            </tr>

                                            <tr className="border-top border-bottom border-2">
                                                <td className="text-left py-1 fw-medium">$60,000 +</td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                            </tr>

                                            <tr className="border-top border-bottom border-2">
                                                <td className="text-left py-1 fw-medium">$600,000 +</td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                            </tr>
                                            <tr className="border-top border-bottom border-2">
                                                <td className="text-left py-1 fw-medium">$1,200,000 +</td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                            </tr>

                                            <tr className="border-top border-bottom border-2">
                                                <td className="text-left py-1 fw-medium">$3,000,000 +</td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                            </tr>

                                            <tr className="border-top border-bottom border-2">
                                                <td className="text-left py-1 fw-medium">$6,000,000 +</td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>2.0%</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>8.5%</div>
                                                </td>
                                            </tr>

                                            <tr className="border-top border-bottom border-2">
                                                <td className="text-left py-1 fw-medium">Minimum Balance</td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>0.01</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>0.1</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>100.0</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>1000.0</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>100.0</div>
                                                </td>
                                                <td className="py-3 text-left fw-medium">
                                                    <div>5000.0</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 px-5 d-flex flex-column flex-md-row justify-content-center align-items-center">
                        <h4 className="me-md-2 mb-3 mb-md-0">Ready to get started?</h4>
                        <input
                            type="email"
                            className="form-control me-md-2 mb-3 mb-md-0"
                            placeholder="Email Address"
                            name="email"
                            id="email"
                            style={{ maxWidth: '350px', minHeight: '50px' }}
                        />
                        <button className="btn btn-primary rounded-30 btn-lg">Get Started</button>
                    </div>

                </div>
            </div>
        </div>
    )
}
