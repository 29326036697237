import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedPairs } from '../../redux/coinSlice';

export default function CoinList({ show, closeCoinList }) {
    const tradePairs = useSelector(state => state.coin.tradePairs);
    const dispatch = useDispatch();

    const handleSelectPair = (pair) => {
        dispatch(setSelectedPairs(pair));
        closeCoinList();  // Hide the CoinList after selecting a pair
    };

    return (
        <div className={show ? 'd-block coinList' : 'd-none coinList'}>
            <div className="row">
                <div className="col-12">
                    <input className='form-control' type="text" placeholder='Search for Market' />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <div className="coin-types d-flex justify-content-center">
                        {/* Filters or buttons can be implemented here */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr />
                    {tradePairs && tradePairs.map((pair, index) => (
                        <div className="d-flex align-items-center justify-content-between p-2 rounded border shadow-sm mb-4 main-coin" key={index} onClick={() => handleSelectPair(pair)}>
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <img alt={pair.coin1.name} className="img-fluid coin-symbol" src={pair.coin1.icon} />
                                <p className="text-center mb-0" style={{ flex: 1 }}>{`${pair.coin1.shortCode}/${pair.coin2.shortCode}`}</p>
                                <img alt={pair.coin2.name} className="img-fluid coin-symbol" src={pair.coin2.icon} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}