import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

export default function EditCoin() {
    const { id } = useParams();  // Get the coin ID from the URL
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [coinData, setCoinData] = useState({
        name: '',
        shortCode: '',
        price: '',
        description: '',
        marketCap: '',
        volume24h: '',
        circulatingSupply: '',
        totalSupply: '',
        maxSupply: '',
        launchDate: '',
        website: '',
        whitepaper: '',
        decimal: ''
    });

    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const fetchCoinData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/coins/${id}`, { withCredentials: true });
                setCoinData(response.data);
                setImageUrl(response.data.icon);
            } catch (error) {
                setApiError('Failed to fetch coin data.');
            }
        };
        fetchCoinData();
    }, [id]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setCoinData({
            ...coinData,
            [id]: value
        });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!coinData.name) formErrors.name = "Coin name is required.";
        if (!coinData.shortCode) formErrors.shortCode = "Short code is required.";
        if (!coinData.price || isNaN(coinData.price)) formErrors.price = "Valid price is required.";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleFileUpload = file => {
        setImageUrl(URL.createObjectURL(file));
        let form = new FormData();
        form.append('icon', file);
        setSelectedImage(form);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setIsLoading(true);
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/coins/${id}`, coinData, { withCredentials: true });
                const data = response.data;

                if (data) {
                    if (selectedImage) {
                        const addImageResponse = await axios.put(`${process.env.REACT_APP_API_URL}/coins/iconUpload/${id}`, selectedImage, { withCredentials: true });
                        if (addImageResponse) {
                            setSuccessMessage("Coin updated successfully!");
                        }
                    } else {
                        setSuccessMessage("Coin updated successfully!");
                    }
                    setIsLoading(false);
                    setTimeout(() => {
                        setSuccessMessage('');
                        navigate('/admin/coins');
                    }, 2000);
                }
            } catch (error) {
                setApiError(error.response?.data?.message || 'Something went wrong. Please try again.');
            }
        }
    };

    return (
        <div className='page'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-6">
                                        <h2 className="mb-3">Edit Coin</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className='d-flex justify-content-center'>
                                    {isLoading && <Spinner animation="border" variant="dark" />}
                                </div>
                                {apiError && <div className="alert alert-danger">{apiError}</div>}
                                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                <form className="account__form needs-validation" onSubmit={handleSubmit} noValidate>
                                    <div className="row g-4">
                                        {/* Coin Name Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="name" className="form-label">Coin Name</label>
                                                <input
                                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    id="name"
                                                    value={coinData.name}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. Bitcoin"
                                                />
                                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                            </div>
                                        </div>
                                        {/* Short Code Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="shortCode" className="form-label">Short Code</label>
                                                <input
                                                    className={`form-control ${errors.shortCode ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    id="shortCode"
                                                    value={coinData.shortCode}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. BTC"
                                                />
                                                {errors.shortCode && <div className="invalid-feedback">{errors.shortCode}</div>}
                                            </div>
                                        </div>
                                        {/* Price Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="price" className="form-label">Price</label>
                                                <input
                                                    className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                                    type="number"
                                                    step="0.01"
                                                    id="price"
                                                    value={coinData.price}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. 30000"
                                                />
                                                {errors.price && <div className="invalid-feedback">{errors.price}</div>}
                                            </div>
                                        </div>
                                        {/* Description Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="description" className="form-label">Description</label>
                                                <textarea
                                                    className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                                    id="description"
                                                    value={coinData.description}
                                                    onChange={handleInputChange}
                                                    placeholder="Description of the coin"
                                                />
                                                {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                            </div>
                                        </div>
                                        {/* Market Cap Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="marketCap" className="form-label">Market Cap</label>
                                                <input
                                                    className={`form-control ${errors.marketCap ? 'is-invalid' : ''}`}
                                                    type="number"
                                                    step="0.01"
                                                    id="marketCap"
                                                    value={coinData.marketCap}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. 1000000000"
                                                />
                                                {errors.marketCap && <div className="invalid-feedback">{errors.marketCap}</div>}
                                            </div>
                                        </div>
                                        {/* Volume 24h Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="volume24h" className="form-label">Volume (24h)</label>
                                                <input
                                                    className={`form-control ${errors.volume24h ? 'is-invalid' : ''}`}
                                                    type="number"
                                                    step="0.01"
                                                    id="volume24h"
                                                    value={coinData.volume24h}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. 50000000"
                                                />
                                                {errors.volume24h && <div className="invalid-feedback">{errors.volume24h}</div>}
                                            </div>
                                        </div>
                                        {/* Circulating Supply Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="circulatingSupply" className="form-label">Circulating Supply</label>
                                                <input
                                                    className={`form-control ${errors.circulatingSupply ? 'is-invalid' : ''}`}
                                                    type="number"
                                                    step="0.01"
                                                    id="circulatingSupply"
                                                    value={coinData.circulatingSupply}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. 18000000"
                                                />
                                                {errors.circulatingSupply && <div className="invalid-feedback">{errors.circulatingSupply}</div>}
                                            </div>
                                        </div>
                                        {/* Total Supply Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="totalSupply" className="form-label">Total Supply</label>
                                                <input
                                                    className={`form-control ${errors.totalSupply ? 'is-invalid' : ''}`}
                                                    type="number"
                                                    step="0.01"
                                                    id="totalSupply"
                                                    value={coinData.totalSupply}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. 21000000"
                                                />
                                                {errors.totalSupply && <div className="invalid-feedback">{errors.totalSupply}</div>}
                                            </div>
                                        </div>
                                        {/* Max Supply Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="maxSupply" className="form-label">Max Supply</label>
                                                <input
                                                    className={`form-control ${errors.maxSupply ? 'is-invalid' : ''}`}
                                                    type="number"
                                                    step="0.01"
                                                    id="maxSupply"
                                                    value={coinData.maxSupply}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. 21000000"
                                                />
                                                {errors.maxSupply && <div className="invalid-feedback">{errors.maxSupply}</div>}
                                            </div>
                                        </div>
                                        {/* Launch Date Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="launchDate" className="form-label">Launch Date</label>
                                                <input
                                                    className={`form-control ${errors.launchDate ? 'is-invalid' : ''}`}
                                                    type="date"
                                                    id="launchDate"
                                                    value={coinData.launchDate}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.launchDate && <div className="invalid-feedback">{errors.launchDate}</div>}
                                            </div>
                                        </div>
                                        {/* Website Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="website" className="form-label">Website</label>
                                                <input
                                                    className={`form-control ${errors.website ? 'is-invalid' : ''}`}
                                                    type="url"
                                                    id="website"
                                                    value={coinData.website}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. https://bitcoin.org"
                                                />
                                                {errors.website && <div className="invalid-feedback">{errors.website}</div>}
                                            </div>
                                        </div>
                                        {/* Whitepaper Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="whitepaper" className="form-label">Whitepaper</label>
                                                <input
                                                    className={`form-control ${errors.whitepaper ? 'is-invalid' : ''}`}
                                                    type="url"
                                                    id="whitepaper"
                                                    value={coinData.whitepaper}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. https://bitcoin.org/bitcoin.pdf"
                                                />
                                                {errors.whitepaper && <div className="invalid-feedback">{errors.whitepaper}</div>}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="decimal" className="form-label">Decimal</label>
                                                <input
                                                    className={`form-control ${errors.decimal ? 'is-invalid' : ''}`}
                                                    type="url"
                                                    id="decimal"
                                                    value={coinData.decimal}
                                                    onChange={handleInputChange}
                                                    placeholder="Ex. 8"
                                                />
                                                {errors.decimal && <div className="invalid-feedback">{errors.decimal}</div>}
                                            </div>
                                        </div>
                                        {/* Image Upload Field */}
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="icon" className="form-label">Coin Icon</label>
                                                <input
                                                    className="form-control"
                                                    type="file"
                                                    id="icon"
                                                    onChange={(e) => handleFileUpload(e.target.files[0])}
                                                />
                                                {imageUrl && (
                                                    <div className="mt-2">
                                                        <img src={imageUrl} alt="Coin Icon" width="50" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary mt-4">
                                        Update Coin
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}