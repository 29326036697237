import React from 'react'
import { FaUsers, FaCoins } from "react-icons/fa";
import CoinTable from '../../components/Tables/CoinTable';

export default function AdminDashboard() {
    return (
        <div className="page">
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-2">
                        <div className="card">
                            <div className="card-body text-center">
                                <FaUsers size={60} className='text-info' />
                                <h2 className='mb-0 mt-1'>5564</h2>
                                <h5 className="card-title mb-0">Users</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="card">
                            <div className="card-body text-center">
                                <FaUsers size={60} className='text-danger' />
                                <h2 className='mb-0 mt-1'>645</h2>
                                <h5 className="card-title mb-0">Unverified Users</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="card">
                            <div className="card-body text-center">
                                <FaUsers size={60} className='text-success' />
                                <h2 className='mb-0 mt-1'>211</h2>
                                <h5 className="card-title mb-0">KYC Completed</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="card">
                            <div className="card-body text-center">
                                <FaUsers size={60} className='text-warning' />
                                <h2 className='mb-0 mt-1'>123</h2>
                                <h5 className="card-title mb-0">In Active Users</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="card">
                            <div className="card-body text-center">
                                <FaCoins size={60} className='text-purple' />
                                <h2 className='mb-0 mt-1'>60</h2>
                                <h5 className="card-title mb-0">Coins</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="card">
                            <div className="card-body text-center">
                                <FaCoins size={60} className='text-teal' />
                                <h2 className='mb-0 mt-1'>120</h2>
                                <h5 className="card-title mb-0">Trade Pairs</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <FaCoins size={30} className='me-2' /> <h3 className='mb-0'>Coins</h3>
                            </div>
                            <div className="card-body">
                                <CoinTable />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <FaUsers size={30} className='me-2' /> <h3 className='mb-0'>Users</h3>
                            </div>
                            <div className="card-body">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
