import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import axios from 'axios';

export default function ResendVerification() {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const [recoverData, setRecoverData] = useState({
        email: "",
    });

    const { email } = recoverData;

    const onInputChange = e => {
        setRecoverData({ ...recoverData, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/resend-verification`, recoverData, { withCredentials: true });
            setSuccessMessage(response.data);
            setTimeout(() => {
                setSuccessMessage('');
                navigate('/signin');
            }, 2000);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setErrorMessage("Email Not Found!");
                } else if (error.response.status === 409) {
                    setErrorMessage("Your email is already verified. You can log in.");
                    setTimeout(() => {
                        setSuccessMessage('');
                        navigate('/signin');
                    }, 3000);
                } else {
                    setErrorMessage("An error occurred. Please try again later.");
                }
                setTimeout(() => {
                    setErrorMessage('');
                }, 2000);
            }
        }
    };

    return (
        <section className="page account padding-top padding-bottom sec-bg-color2">
            <div className="container">
                <div className="account__wrapper" data-aos="fade-up" data-aos-duration="800">
                    <div className="row g-4">
                        <div className="col-lg-12">
                            <div className="account__content account__content--style1">
                                <div className="account__header">
                                    <h2>Resend Verification Link</h2>
                                    <p>If your verification link has expired, you can request a new one here.</p>
                                    {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                                    {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
                                </div>
                                <form onSubmit={submitHandler}>
                                    <div className="row mb-3">
                                        <label htmlFor="email" className="col-sm-4 col-md-3 col-form-label">Email</label>
                                        <div className="col-sm-6 col-md-9">
                                            <input
                                                type="email"
                                                name='email'
                                                value={email}
                                                onChange={onInputChange}
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter Your Email Address"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-3">
                                        <button className="trk-btn trk-btn--border trk-btn--primary mt-4 me-2" type='submit'>Submit</button>
                                    </div>
                                </form>
                                <div className="account__switch">
                                    <p>
                                        <Link to="/signin" className="style2"> <FaArrowLeft />
                                            Back to <span>Login</span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="account__shape">
                <span className="account__shape-item account__shape-item--1">
                    <img src="/assets/images/others/4.png" alt="shape-icon" /></span>
            </div>
        </section>
    );
}