import React from 'react'
import OTCDesk from '../../components/OTC/OTCDesk'
import DeskFeatures from '../../components/OTC/DeskFeatures'
import Broker from '../../components/Institutions/Broker'

export default function OTC() {
    return (
        <div className='page'>
            <OTCDesk />
            <DeskFeatures />
            <Broker />
        </div>
    )
}
