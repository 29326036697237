import React from 'react'
import InstitutionalProducts from '../../components/Institutions/InstitutionalProducts'
import Ramps from '../../components/Institutions/Ramps'
import Remittance from '../../components/Institutions/Remittance'
import OTCHighVolume from '../../components/Institutions/OTCHighVolume'
import Broker from '../../components/Institutions/Broker'

export default function Institutions() {
    return (
        <div className='page'>
            <InstitutionalProducts />
            <Ramps />
            <Remittance />
            <OTCHighVolume />
            <Broker />
        </div>
    )
}
