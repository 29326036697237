import React from 'react';
import { Tab, Nav } from 'react-bootstrap';

export default function Features() {
    return (
        <section className="feature feature--style1 padding-bottom padding-top bg-color">
            <div className="container">
                <Tab.Container id="left-tabs-example" defaultActiveKey="feat-pills-one">
                    <div className="feature__wrapper">
                        <div className="row g-5 align-items-center justify-content-between">
                            <div className="col-md-6 col-lg-5">
                                <div className="feature__content">
                                    <div className="feature__content-inner">
                                        <div className="section-header">
                                            <h2 className="mb-15 mt-minus-5">
                                                Why should you use <span>XENLY</span>?
                                            </h2>
                                            <p className="mb-0">
                                                Unlock the full potential of our product with our amazing features and top-notch support.
                                            </p>
                                        </div>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="feat-pills-one" className='features-item'>Experience Unmatched Reliability for Confident Trading</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="feat-pills-two" className='features-item'>Leverage Cutting-Edge Technology for Seamless Trading</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="feat-pills-three" className='features-item'>Enjoy Comprehensive Support with 24/7 Assistance</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="feat-pills-four" className='features-item'>Benefit from Competitive Rates with Transparent Pricing</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <div className="feature__thumb pt-5 pt-md-0">
                                    <div className="feature__thumb-inner">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="feat-pills-one">
                                                <div className="feature__image floating-content">
                                                    <img src="/assets/images/feature/1.png" alt="Feature" />
                                                    <div className="floating-content__top-right floating-content__top-right--style2">
                                                        <div className="floating-content__item floating-content__item--style2 text-center">
                                                            <img src="/assets/images/feature/5.png" alt="rating" />
                                                            <p className="style2">Lower fees</p>
                                                        </div>
                                                    </div>
                                                    <div className="floating-content__bottom-left floating-content__bottom-left--style2">
                                                        <div className="floating-content__item floating-content__item--style3 d-flex align-items-center">
                                                            <h3 className="style2">
                                                                <span
                                                                    className="purecounter"
                                                                    data-purecounter-start="0"
                                                                    data-purecounter-end="30"
                                                                >
                                                                    30
                                                                </span>
                                                                +
                                                            </h3>
                                                            <p className="ms-3 style2">Coins available for Trade</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="feat-pills-two">
                                                <div className="feature__image floating-content">
                                                    <img src="/assets/images/feature/2.png" alt="Feature" />
                                                    <div className="floating-content__top-right floating-content__top-right--style2">
                                                        <div className="floating-content__item floating-content__item--style2 text-center">
                                                            <img src="/assets/images/feature/6.png" alt="rating" />
                                                            <p className="style2">Faster Trading</p>
                                                        </div>
                                                    </div>
                                                    <div className="floating-content__bottom-left floating-content__bottom-left--style2">
                                                        <div className="floating-content__item floating-content__item--style3 d-flex align-items-center">
                                                            <h3 className="style2">
                                                                <span
                                                                    className="purecounter"
                                                                    data-purecounter-start="0"
                                                                    data-purecounter-end="30"
                                                                >
                                                                    30
                                                                </span>
                                                                +
                                                            </h3>
                                                            <p className="ms-3 style2">Coins available for Trade</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="feat-pills-three">
                                                <div className="feature__image floating-content">
                                                    <img src="/assets/images/feature/1.png" alt="Feature" />
                                                    <div className="floating-content__top-right floating-content__top-right--style2">
                                                        <div className="floating-content__item floating-content__item--style2 text-center">
                                                            <img src="/assets/images/feature/7.png" alt="rating" />
                                                            <p className="style2">Interest Rate For Stacking</p>
                                                        </div>
                                                    </div>
                                                    <div className="floating-content__bottom-left floating-content__bottom-left--style2">
                                                        <div className="floating-content__item floating-content__item--style3 d-flex align-items-center">
                                                            <h3 className="style2">
                                                                <span
                                                                    className="purecounter"
                                                                    data-purecounter-start="0"
                                                                    data-purecounter-end="30"
                                                                >
                                                                    30
                                                                </span>
                                                                +
                                                            </h3>
                                                            <p className="ms-3 style2">Coins available for Trade</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="feat-pills-four">
                                                <div className="feature__image floating-content">
                                                    <img src="/assets/images/feature/2.png" alt="Feature" />
                                                    <div className="floating-content__top-right floating-content__top-right--style2">
                                                        <div className="floating-content__item floating-content__item--style2 text-center">
                                                            <img src="/assets/images/feature/8.png" alt="rating" />
                                                            <p className="style2">Interest Rate For Stacking</p>
                                                        </div>
                                                    </div>
                                                    <div className="floating-content__bottom-left floating-content__bottom-left--style2">
                                                        <div className="floating-content__item floating-content__item--style3 d-flex align-items-center">
                                                            <h3 className="style2">
                                                                <span
                                                                    className="purecounter"
                                                                    data-purecounter-start="0"
                                                                    data-purecounter-end="30"
                                                                >
                                                                    30
                                                                </span>
                                                                +
                                                            </h3>
                                                            <p className="ms-3 style2">Coins available for Trade</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
            <div className="feature__shape">
                <span className="feature__shape-item feature__shape-item--1">
                    <img src="/assets/images/feature/shape/1.png" alt="shape-icon" />
                </span>
                <span className="feature__shape-item feature__shape-item--2">
                    <span></span>
                </span>
            </div>
        </section>
    );
}