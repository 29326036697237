import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter } from 'react-icons/fa';
import axios from 'axios';

export default function ContactUs() {
    const [errors, setErrors] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData);
            if (response.status === 200) {
                setSuccessMessage('Your message has been submitted successfully!');
                // Clear form after successful submission
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                });
                // Clear success message after 3 seconds
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            }
        } catch (error) {
            setErrors('There was an error submitting your message. Please try again.');
            // Clear error message after 3 seconds
            setTimeout(() => {
                setErrors('');
            }, 3000);
        }
    };

    return (
        <div className="page">
            <div className="contact padding-top padding-bottom">
                <div className="container">
                    <div className="contact__wrapper">
                        <div className="row g-5">
                            <div className="col-md-5">
                                <div className="contact__info" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="contact__social">
                                        <h3>let’s <span>get in touch</span> with us</h3>
                                        <ul className="social">
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style4 active">
                                                    <FaFacebookF />
                                                </a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style4">
                                                    <FaInstagram />
                                                </a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style4">
                                                    <FaLinkedinIn />
                                                </a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style4">
                                                    <FaYoutube />
                                                </a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style4">
                                                    <FaTwitter />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="contact__details">
                                        <div className="contact__item" data-aos="fade-right" data-aos-duration="1000">
                                            <div className="contact__item-inner">
                                                <div className="contact__item-thumb">
                                                    <span><img src="/assets/images/contact/1.png" alt="contact-icon" className="dark" /></span>
                                                </div>
                                                <div className="contact__item-content">
                                                    <p>
                                                        <a href="https://t.me/5228013193">5228013193</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contact__item" data-aos="fade-right" data-aos-duration="1100">
                                            <div className="contact__item-inner">
                                                <div className="contact__item-thumb">
                                                    <span><img src="/assets/images/contact/2.png" alt="contact-icon" className="dark" /></span>
                                                </div>
                                                <div className="contact__item-content">
                                                    <a href="mailto:Support@xenely.com">Support@xenely.com</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="contact__form">
                                    {errors && <div className="alert alert-danger">{errors}</div>}
                                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="row g-4">
                                            <div className="col-12">
                                                <div>
                                                    <label htmlFor="name" className="form-label">Name</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="name"
                                                        placeholder="Full Name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div>
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        id="email"
                                                        placeholder="Email here"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div>
                                                    <label htmlFor="message" className="form-label">Message</label>
                                                    <textarea
                                                        cols="30"
                                                        rows="5"
                                                        className="form-control"
                                                        id="message"
                                                        placeholder="Enter Your Message"
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        required
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="trk-btn trk-btn--border trk-btn--primary mt-4 d-block">
                                            contact us now
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact__shape">
                    <span className="contact__shape-item contact__shape-item--1">
                        <img src="/assets/images/others/4.png" alt="shape-icon" />
                    </span>
                    <span className="contact__shape-item contact__shape-item--2">
                        <span></span>
                    </span>
                </div>
            </div>
        </div>
    );
}
