import React from 'react'

export default function OTCDesk() {
    return (
        <div className='otc-desk' style={{ backgroundColor: '#182c49' }}>
            <div className="container">
                <div className="row py-5 align-items-center">
                    <div className="col-12 col-md-5 py-5 text-white">
                        <h2 className="mb-3">High-Volume OTC Desk</h2>
                        <p>A premium trading solution for those seeking extensive asset support, zero slippage, and immediate execution on orders of any size for high-volume traders</p>
                        <p>Experience private trading away from public order books.</p>
                        <p className='text-warning'>The Minimum order is R300,000 but call us to accommodate your needs case-by-case</p>
                    </div>
                    <div className="col-12 col-md-7 p-0 p-md-5">
                        <div className="card m-2 m-md-5 rounded">
                            <div className="card-body py-5 px-3">
                                <h2 className="mb-3 text-center">Request OTC Access</h2>
                                <form>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <input type="text" name="first" id="first" className='form-control' placeholder='First Name' />
                                        </div>
                                        <div className="col-6">
                                            <input type="text" name="last" id="last" className='form-control' placeholder='Last Name' />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <input type="text" name="contact" id="contact" className='form-control' placeholder='Contact Number' />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <input type="email" name="email" id="email" className='form-control' placeholder='Contact Email' />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center">
                                            <button type='button' className='btn btn-success btn-lg'>Request a Call</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
