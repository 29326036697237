import React, { useState } from 'react'
import TradeBuySell from '../../components/Trades/TradeBuySell';

export default function BuySell() {
    const [activeButton, setActiveButton] = useState('instant');
    return (
        <div className='page'>
            <div className="container">
                <div className="row  d-flex justify-content-center">
                    <div className="col-12 col-md-8">
                        <div className="coin-icons text-center mb-4">
                            <div className="from-coin">
                                <img alt="btc" className="img-fluid" src="/assets/images/coins/BTC.png" style={{ Width: '40px', height: '40px' }} />
                            </div>
                            <img src="/assets/images/double-arrow.svg" alt="double-arrow" style={{ maxWidth: '40px' }} />
                            <div className="to-coin">
                                <img alt="zar" className="img-fluid" src="/assets/images/coins/SA.png" style={{ Width: '40px', height: '40px' }} />
                            </div>
                        </div>
                        <div className='title d-flex justify-content-center align-items-center'>
                            <h2 className='text-center mb-3 me-3'>{activeButton === 'instant' ? 'Instant Buy & Sell' : 'Instant - Post Trade Settlement'}</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5">
                        <div className="buttons text-center bg-white py-2 border-radius">
                            <button type="button" class={`btn me-1 ${activeButton === 'instant' ? 'btn-primary' : 'btn-outline-dark'}`} onClick={() => setActiveButton('instant')}>Instant Buy & Sell</button>
                            <button type="button" class={`btn ${activeButton === 'settlement' ? 'btn-primary' : 'btn-outline-dark'}`} onClick={() => setActiveButton('settlement')}>Post Trade Settlement</button>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-2">
                    <div className="col-12 col-md-5">
                        <div className="trade-options">
                            <div className="card rounded-xl w-100">
                                <div className="card-body">
                                    <TradeBuySell />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
