import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './components/Layouts/Footer';
import Header from './components/Layouts/Header';
import Forgot from './pages/Auth/Forgot';
import SignIn from './pages/Auth/SignIn';
import Signup from './pages/Auth/Signup';
import Home from './pages/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './pages/dashboard/Dashboard';
import Preloader from './components/utils/Preloader';
import BuySell from './pages/BuySell/BuySell';
import Institutions from './pages/Institutions/Institutions';
import OTC from './pages/OTC/OTC';
import GoToTop from './components/utils/GoToTop';
import InterestAccount from './pages/InterestAccount/InterestAccount';
import Coins from './pages/Coins/Coins';
import { useSelector } from 'react-redux';
import NotFound from './pages/NotFound/NotFound';
import VerifyEmail from './utils/VerifyEmail';
import AdminDashboard from './pages/dashboard/AdminDashboard';
import ResetPassword from './pages/Auth/ResetPassword';
import ContactUs from './pages/Contact/ContactUs';
import Careers from './pages/Careers/Careers';
import ResendVerification from './pages/Auth/ResendVerification';
import AddCoin from './pages/Coins/AddCoin';
import EditCoin from './pages/Coins/EditCoin';
import TradePairs from './pages/Coins/TradePairs';

function App() {
  const [loading, setLoading] = useState(true);
  const isLogged = useSelector((state) => state.user?.isLogged);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const commonRoutes = (
    <>
      <Route path="/" element={<Home />} />
      <Route path="/buy-and-sell" element={<BuySell />} />
      <Route path="/institutions" element={<Institutions />} />
      <Route path="/otc" element={<OTC />} />
      <Route path="/interest-accounts" element={<InterestAccount />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/resend-verify" element={<ResendVerification />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/careers" element={<Careers />} />
      <Route path='/reset-password/:token' element={<ResetPassword />} />
      <Route path="*" element={<NotFound />} />
    </>
  );

  return (
    <BrowserRouter>
      <div className="App">
        {loading && <Preloader />}
        <Header />
        <Routes>
          {commonRoutes}
          {isLogged && (
            <>
              <Route path="/client/dashboard" element={<Dashboard />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/coins" element={<Coins />} />
              <Route path="/admin/add-coin" element={<AddCoin />} />
              <Route path="/admin/coins/edit/:id" element={<EditCoin />} />
              <Route path="/admin/coins/trade-pairs" element={<TradePairs />} />
            </>
          )}
        </Routes>
        <Footer />
        <GoToTop />
      </div>
    </BrowserRouter>
  );
}

export default App;
