import React from 'react'

export default function DeskFeatures() {
    return (
        <div className="bg-white">
            <div className='container'>
                <div className="row py-5">
                    <div className="col-12">
                        <h2 className="mb-3">Welcome to Africa's deepest liquidity venue</h2>
                        <h6>Some of the Desk's features include</h6>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <div className="row  mb-3">
                            <div className="col-2">
                                <div className="bg-white rounded d-flex justify-content-center align-items-center p-2">
                                    <img className='img-fluid' style={{ width: '40px' }} src="/assets/images/feature/post_trade_settlement.svg" alt="post trade settlement" />
                                </div>
                            </div>
                            <div className="col-8">
                                <h5>Post-trade settlement</h5>
                                <p>We can open up large credit lines to enable immediate trade execution with delayed settlement. Trade now and deposit the funds later.</p>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row  mb-3">
                            <div className="col-2">
                                <div className="bg-white rounded d-flex justify-content-center align-items-center p-2">
                                    <img className='img-fluid' style={{ width: '40px' }} src="/assets/images/feature/24_7_communication_line.svg" alt="post trade settlement" />
                                </div>
                            </div>
                            <div className="col-8">
                                <h5>24/7 communication line</h5>
                                <p>We'll establish a 24/7 communcation line between you and the trading desk. Request a quote or ask for assistance at any time.</p>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <div className="row  mb-3">
                            <div className="col-2">
                                <div className="bg-white rounded d-flex justify-content-center align-items-center p-2">
                                    <img className='img-fluid' style={{ width: '40px' }} src="/assets/images/feature/full_asset_support.svg" alt="post trade settlement" />
                                </div>
                            </div>
                            <div className="col-8">
                                <h5>Full asset support</h5>
                                <p>In addition to OTC crypto/fiat trading, the OVEX OTC desk supports fiat/fiat and crypto/crypto pairings on all digital assets and fiat currencies listed on the OVEX Exchange.</p>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row  mb-3">
                            <div className="col-2">
                                <div className="bg-white rounded d-flex justify-content-center align-items-center p-2">
                                    <img className='img-fluid' style={{ width: '40px' }} src="/assets/images/feature/ultra_deep_liquidity.svg" alt="post trade settlement" />
                                </div>
                            </div>
                            <div className="col-8">
                                <h5>Ultra-deep liquidity</h5>
                                <p>Execute high volume trades at one price with zero slippage and zero trading fees.</p>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <div className="row  mb-3">
                            <div className="col-2">
                                <div className="bg-white rounded d-flex justify-content-center align-items-center p-2">
                                    <img className='img-fluid' style={{ width: '40px' }} src="/assets/images/feature/margin_support.svg" alt="post trade settlement" />
                                </div>
                            </div>
                            <div className="col-8">
                                <h5>Margin services</h5>
                                <p>Open leveraged positions for maximum efficiency.</p>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row  mb-3">
                            <div className="col-2">
                                <div className="bg-white rounded d-flex justify-content-center align-items-center p-2">
                                    <img className='img-fluid' style={{ width: '40px' }} src="/assets/images/feature/bespoke_trading.svg" alt="post trade settlement" />
                                </div>
                            </div>
                            <div className="col-8">
                                <h5>Bespoke trading</h5>
                                <p>Get access to a tailored trading experience with a personalised trading team available 24/7 to ensure you achieve your investing objectives.</p>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <div className="row  mb-3">
                            <div className="col-2">
                                <div className="bg-white rounded d-flex justify-content-center align-items-center p-2">
                                    <img className='img-fluid' style={{ width: '40px' }} src="/assets/images/feature/tightest_spreads.svg" alt="post trade settlement" />
                                </div>
                            </div>
                            <div className="col-8">
                                <h5>Tightest spreads</h5>
                                <p>Tiered spread structure. High volume traders pay lower spreads.</p>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row  mb-3">
                            <div className="col-2">
                                <div className="bg-white rounded d-flex justify-content-center align-items-center p-2">
                                    <img className='img-fluid' style={{ width: '40px' }} src="/assets/images/feature/robust_on_off_ramps.svg" alt="post trade settlement" />
                                </div>
                            </div>
                            <div className="col-8">
                                <h5>Robust on/off ramps</h5>
                                <p>Leverage our robust and versatile on/off ramp ecosystem to move from crypto into fiat and back again seamlessly.</p>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
