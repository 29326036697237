import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { FaAngleLeft, FaAngleRight, FaQuoteRight } from 'react-icons/fa';

const testimonials = [
    {
        text: "The above testimonial is about Martha Chumo, who taught herself to code in one summer. This testimonial example works because it allows prospective customers to see themselves in Codeacademy’s current customer base.",
        authorImg: "/assets/images/testimonial/1.png",
        authorName: "Mobarok Hossain",
        authorDesignation: "Trade Master",
    },
    {
        text: "In the above testimonial, a customer named Jeanine shares her experience with Briogeo’s products. While the post is scattered with too many product mentions, it takes full advantage of its real estate by allowing the writer to tell",
        authorImg: "/assets/images/testimonial/2.png",
        authorName: "Guy Hawkins",
        authorDesignation: "Trade Boss",
    },
    {
        text: "The above testimonial is about Martha Chumo, who taught herself to code in one summer. This testimonial example works because it allows prospective customers to see themselves in Codeacademy’s current customer base.",
        authorImg: "/assets/images/testimonial/6.png",
        authorName: "Belal Hossain",
        authorDesignation: "Trade Genius",
    }
];

export default function Testimonials() {
    return (
        <section className="testimonial padding-top padding-bottom-style2 bg-color">
            <div className="container">
                <div className="section-header d-md-flex align-items-center justify-content-between">
                    <div className="section-header__content">
                        <h2 className="mb-15">Connect with <span>our Clients</span></h2>
                        <p className="mb-0">We love connecting with our clients to hear about their experiences and how we can improve.</p>
                    </div>
                    <div className="section-header__action">
                        <div className="swiper-nav">
                            <button className="swiper-nav__btn testimonial__slider-prev">
                                <FaAngleLeft />
                            </button>
                            <button className="swiper-nav__btn testimonial__slider-next">
                                <FaAngleRight />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="testimonial__wrapper" data-aos="fade-up" data-aos-duration="1000">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={30}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 1,
                            },
                            1024: {
                                slidesPerView: 2,
                            },
                        }}
                        navigation={{
                            prevEl: '.testimonial__slider-prev',
                            nextEl: '.testimonial__slider-next'
                        }}
                        className="testimonial__slider swiper"
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index}>
                                <div className="testimonial__item testimonial__item--style1">
                                    <div className="testimonial__item-inner">
                                        <div className="testimonial__item-content">
                                            <p className="mb-0">{testimonial.text}</p>
                                            <div className="testimonial__footer">
                                                <div className="testimonial__author">
                                                    <div className="testimonial__author-thumb">
                                                        <img src={testimonial.authorImg} alt="author" />
                                                    </div>
                                                    <div className="testimonial__author-designation">
                                                        <h6>{testimonial.authorName}</h6>
                                                        <span>{testimonial.authorDesignation}</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial__quote">
                                                    <FaQuoteRight />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}
