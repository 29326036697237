import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { FiTrash, FiEye, FiEdit, FiHome } from "react-icons/fi"
import Swal from "sweetalert2";
import { MdOutlineClose } from "react-icons/md";
import CoinDetails from '../../components/Modals/CoinDetails';
import { TiTick } from "react-icons/ti";

export default function CoinTable() {
    const [coins, setCoins] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState(null);

    const getCoins = async () => {
        setIsLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/coins`, { withCredentials: true })
        if (response) {
            setCoins(response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1))
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getCoins()
    }, []);

    const handleShowDetails = (coin) => {
        setSelectedCoin(coin);
        setShowDetails(true);
    };

    const handleCloseDetails = () => {
        setShowDetails(false);
        setSelectedCoin(null);
    };

    const columns = [
        {
            name: "No.",
            cell: (row, index, column, id) => <div>{index + 1}</div>,
            selector: row => (console.log(row)),
            width: "60px",
            center: true
        },
        {
            name: 'Name',
            cell: (row) => <div><img src={row.icon} width={40} height={40} className="my-2 rounded-circle" alt={`${row.name}`} />  {row.name}</div>,
            selector: row => (row),
        },
        {
            name: 'Short Code',
            selector: row => (row.shortCode),
        },
        {
            name: 'Price',
            selector: row => <>${row.price}</>,
        },
        {
            name: 'Active',
            cell: row => (
                <button
                    className={`btn btn-sm me-1 ${row.isActive ? 'btn-danger' : 'btn-success'}`}
                    onClick={() => CoinStatusChange(row._id, row.isActive)}
                >
                    {row.isActive ? <MdOutlineClose className='text-white' /> : <TiTick className='text-white' />}
                </button>
            ),
            selector: row => row.isActive,
            center: true
        },
        {
            name: 'Action',
            cell: row => <div>
                <button className='btn btn-warning me-1' onClick={() => handleShowDetails(row)}><FiEye title="View Details" /></button>
                <Link to={`/admin/coins/edit/` + row._id} className='btn btn-info me-1'><FiEdit title="Edit Coin" /></Link>
                <button className='btn btn-danger' onClick={() => deleteCoin(row._id)}><FiTrash title="Delete" /></button>
            </div>,
            center: 'yes'
        },
    ];
    const CoinStatusChange = async (coinId, currentStatus) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to ${currentStatus ? 'deactivate' : 'activate'} this Coin?`,
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`${process.env.REACT_APP_API_URL}/coins/${coinId}`,
                    { isActive: !currentStatus },
                    { withCredentials: true }
                )
                    .then(res => {
                        getCoins();
                        Swal.fire({
                            title: "Done!",
                            text: `Coin successfully ${currentStatus ? 'deactivated' : 'activated'}`,
                            icon: "success",
                            timer: 2000,
                            button: false
                        });
                    });
            }
        });
    };

    const deleteCoin = async (coinId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this Coin?",
            //icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API_URL}/coins/` + coinId, { withCredentials: true })
                    .then(res => {
                        getCoins()
                        Swal.fire({
                            title: "Done!",
                            text: "Coin Successfully Deleted",
                            icon: "success",
                            timer: 2000,
                            button: false
                        })

                    });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }
    return (
        <div className="row">
            <div className="col">
                <div className='d-flex justify-content-center'>
                    {isLoading && <Spinner animation="border" variant="dark" />}
                </div>
                <DataTable
                    columns={columns}
                    data={coins}
                    pagination
                    striped
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                />
                {selectedCoin && (
                    <CoinDetails
                        show={showDetails}
                        handleClose={handleCloseDetails}
                        coin={selectedCoin}
                    />
                )}
            </div>
        </div>
    )
}
