import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CoinTable from '../../components/Tables/CoinTable';

export default function Coins() {

    return (
        <div className='page'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-6">
                                        <h2 className="mb-3">Coin List</h2>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <Link to="/admin/add-coin" className='btn btn-primary btn-sm me-2 my-2 text-white'>Add Coin</Link>
                                    </div>
                                </div>
                                <CoinTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
