import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import axios from 'axios';

export default function Forgot() {
    const [ErrorMessage, setErrorMessage] = useState();
    const [SuccessMessage, setSuccessMessage] = useState();
    const navigate = useNavigate()
    const [recoverData, setRecoverData] = useState({
        email: "",
    });
    const { email } = recoverData
    const onInputChange = e => {
        setRecoverData({ ...recoverData, [e.target.name]: e.target.value });
    };
    const SubmitHandler = async (e) => {
        e.preventDefault();
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/forgot-password`, recoverData, { withCredentials: true }).catch(function (error) {
            if (error.response) {
                // console.log(error.response.data);
                // console.log(error.response.status);
                if (error.response.status === 401 || 500) {
                    setErrorMessage("Email Not Found!")
                    setTimeout(() => {
                        setErrorMessage()
                    }, 2000)
                }
                // console.log(error.response.headers);
            }
        });
        const data = response.data
        if (data) {
            setSuccessMessage("A password reset link has been sent to this email address");
            setTimeout(() => {
                setSuccessMessage()
                navigate('/signin')
            }, 2000)
        } else {

        }
    }
    return (
        <section className="account padding-top padding-bottom sec-bg-color2">
            <div className="container">
                <div className="account__wrapper" data-aos="fade-up" data-aos-duration="800">
                    <div className="row g-4">
                        <div className="col-lg-12">
                            <div className="account__content account__content--style1">
                                <div className="account__header">
                                    <h2>Reset Your Password</h2>
                                    <p>Hey there! Forgot your password? No worries, just click "forgot password" and follow the steps to
                                        recover it. Easy peasy lemon squeezy!</p>
                                    {ErrorMessage && <div className="alert alert-danger" role="alert">{ErrorMessage} </div>}
                                    {SuccessMessage && <div className="alert alert-success" role="alert">{SuccessMessage} </div>}
                                </div>
                                <form onSubmit={SubmitHandler}>
                                    <div className="row mb-3">
                                        <label htmlFor="email" className="col-sm-4 col-md-3 col-form-label">Email</label>
                                        <div className="col-sm-6 col-md-9">
                                            <input type="email" name='email' value={email} onChange={e => onInputChange(e)} className="form-control" id="email" placeholder="Enter Your Email Address" required />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-3">
                                        <button className="trk-btn trk-btn--border trk-btn--primary mt-4 me-2" type='submit'>Submit</button>
                                    </div>
                                </form>
                                <div className="account__switch">
                                    <p>
                                        <Link to="/signin" className="style2"> <FaArrowLeft />
                                            Back to <span>Login</span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="account__shape">
                <span className="account__shape-item account__shape-item--1">
                    <img src="/assets/images/others/4.png" alt="shape-icon" /></span>
            </div>
        </section>
    )
}
