import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLogged: false,
        userDetails: {},
        isRequestOTP: false,
    },
    reducers: {
        setIsLogged: (state, action) => {
            state.isLogged = action.payload
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload
        },
        setIsRequestOTP: (state, action) => {
            state.isRequestOTP = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setIsLogged, setUserDetails, setIsRequestOTP } = userSlice.actions

export default userSlice.reducer