import { createSlice } from '@reduxjs/toolkit'

export const coinSlice = createSlice({
    name: 'coin',
    initialState: {
        coins: [],
        tradePairs: [],
        selectedPair: null,
    },
    reducers: {
        setCoinData: (state, action) => {
            state.coins = action.payload
        },
        setTradePairs: (state, action) => {
            state.tradePairs = action.payload
        },
        setSelectedPairs: (state, action) => {
            state.selectedPair = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setCoinData, setTradePairs, setSelectedPairs } = coinSlice.actions

export default coinSlice.reducer