import React from 'react'

export default function Careers() {
    return (
        <div className='page'>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2 className="mb-3">Careers</h2>
                        <hr />
                        <h4>Senior Site Reliability Engineer</h4>
                        <p>Xenly is an all-in-one Crypto Exchange Platform built without limits. Designed to be super-fast, incredibly secure, and infinitely scalable.</p>
                        <p>We are looking for a Cloud AWS Engineer remote working, but as when required to travel to existing to offices</p>
                        <strong className='my-2'>Responsibilities:</strong>
                        <ul className='list-style-disc'>
                            <li>Maintain and operate Xenly  infrastructure in a multi-cloud environment</li>
                            <li>Contribute to improve our incident management lifecycle for overall reliability</li>
                            <li>Contribute to improve our Postmortem philosophy</li>
                            <li>Contribute to improve our DevOps culture</li>
                            <li>Deploy and maintain Rollups-as-a-Service (RaaS) core components and related observability stacks</li>
                            <li>Evaluate and modernize our existing infrastructure and deployment strategies to align with the latest industry standards</li>
                            <li>Maintain and enhance our CI/CD pipeline and its governance</li>
                            <li>Be on-call rotation to provide operational support and service availability</li>
                            <li>Participate and conduct regular team meetings</li>
                        </ul>
                        <p>Provide insights and recommendations on system design and scalability, focusing on reliability, security and efficiency in a Web3 context
                            Be an active team member by always looking out for cost-effective innovative solutions and by facilitating the adoption of industry standards
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
