import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';

const blogData = [
    {
        imgSrc: "/assets/images/blog/1.png",
        tag: "Forex trading",
        title: "Swing Trading Definition",
        description: "Our platform is not about trading—it's also a hub for knowledge and learning. We provide resources.",
        authorImg: "/assets/images/blog/author/1.png",
        authorName: "Vasha Gueye",
        date: "20/6/2023"
    },
    {
        imgSrc: "/assets/images/blog/2.png",
        tag: "Trading market",
        title: "Hedge funds work?",
        description: "To cater to your individual trading preferences, we offer a variety of order types, including market.",
        authorImg: "/assets/images/blog/author/2.png",
        authorName: "Abhivibha Kanmani",
        date: "30/5/2023"
    },
    {
        imgSrc: "/assets/images/blog/3.png",
        tag: "Investment",
        title: "Options Trading business?",
        description: "Security is our top priority, and we employ robust measures to ensure the safety of your funds.",
        authorImg: "/assets/images/blog/author/3.png",
        authorName: "Hulya Aydin",
        date: "12/07/2023"
    }
];

export default function Blogs() {
    return (
        <section className="blog padding-top padding-bottom">
            <div className="container">
                <div className="section-header d-md-flex align-items-center justify-content-between">
                    <div className="section-header__content">
                        <h2 className="mb-15"><span>Articles</span> for Pro Traders</h2>
                        <p className="mb-0">Hey there pro traders, check out these articles with tips to take your trading game to the next level!</p>
                    </div>
                    <div className="section-header__action">

                    </div>
                </div>
                <div className="blog__wrapper">
                    <Swiper
                        spaceBetween={30}
                        pagination={{ clickable: true }}
                        navigation={{
                            prevEl: '.blog__slider-prev',
                            nextEl: '.blog__slider-next'
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                        className="blog__slider swiper"
                    >
                        {blogData.map((blog, index) => (
                            <SwiperSlide key={index}>
                                <div className="blog__item blog__item--style2">
                                    <div className="blog__item-inner">
                                        <div className="blog__thumb">
                                            <img src={blog.imgSrc} alt="blog" />
                                        </div>
                                        <div className="blog__content">
                                            <div className="blog__meta">
                                                <span className="blog__meta-tag blog__meta-tag--style1">{blog.tag}</span>
                                            </div>
                                            <h5><Link to="/">{blog.title}</Link></h5>
                                            <p className="mb-15">{blog.description}</p>
                                            <div className="blog__writer">
                                                <div className="blog__writer-thumb">
                                                    <img src={blog.authorImg} alt="author" />
                                                </div>
                                                <div className="blog__writer-designation">
                                                    <h6 className="mb-0">{blog.authorName}</h6>
                                                    <span>{blog.date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="text-center">
                    <Link to="/" className="trk-btn trk-btn--border trk-btn--primary mt-40">View more</Link>
                </div>
            </div>
            <div className="blog__shape">
                <span className="blog__shape-item blog__shape-item--1"><span></span></span>
            </div>
        </section>
    );
}
