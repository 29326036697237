import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import CoinList from './CoinList';

export default function BuyForm() {
    const [showCoinList, setShowCoinList] = useState(false);
    const [tradeData, setTradeData] = useState({
        coin1: '',
        coin2: ''
    });
    const { coin1, coin2 } = tradeData;

    const { tradePairs, selectedPair } = useSelector(state => state.coin);

    const defaultPair = selectedPair ||
        (tradePairs && tradePairs.length > 0 && tradePairs.find(pair => pair.default)) || (tradePairs && tradePairs[0]);
    const coin1USDPrice = defaultPair?.coin1?.price || 0;
    const coin2USDPrice = defaultPair?.coin2?.price || 0;
    const coin1Decimal = defaultPair?.coin1?.decimal || 8;
    const coin2Decimal = defaultPair?.coin2?.decimal || 8;

    // Debounced functions
    const debouncedCalculateCoin2Value = useCallback(debounce((coin1Amount) => {
        if (coin1Amount && coin1USDPrice && coin2USDPrice) {
            const coin2Amount = (coin1Amount * coin1USDPrice) / coin2USDPrice;
            setTradeData(prevState => ({
                ...prevState,
                coin2: coin2Amount.toFixed(coin2Decimal)
            }));
        }
    }, 1000), [coin1USDPrice, coin2USDPrice, coin2Decimal]);

    const debouncedCalculateCoin1Value = useCallback(debounce((coin2Amount) => {
        if (coin2Amount && coin1USDPrice && coin2USDPrice) {
            const coin1Amount = (coin2Amount * coin2USDPrice) / coin1USDPrice;
            setTradeData(prevState => ({
                ...prevState,
                coin1: coin1Amount.toFixed(coin1Decimal)
            }));
        }
    }, 1000), [coin1USDPrice, coin2USDPrice, coin1Decimal]);

    useEffect(() => {
        // Reset tradeData when defaultPair changes
        setTradeData({
            coin1: '',
            coin2: ''
        });
        debouncedCalculateCoin1Value.cancel();
        debouncedCalculateCoin2Value.cancel();
    }, [defaultPair, debouncedCalculateCoin1Value, debouncedCalculateCoin2Value]);

    useEffect(() => {
        if (coin1) {
            debouncedCalculateCoin2Value(parseFloat(coin1));
        } else {
            setTradeData(prevState => ({
                ...prevState,
                coin2: '' // Clear coin2 if coin1 is empty
            }));
        }
        return () => {
            debouncedCalculateCoin2Value.cancel();
        };
    }, [coin1, debouncedCalculateCoin2Value]);

    useEffect(() => {
        if (coin2) {
            debouncedCalculateCoin1Value(parseFloat(coin2));
        } else {
            setTradeData(prevState => ({
                ...prevState,
                coin1: '' // Clear coin1 if coin2 is empty
            }));
        }
        return () => {
            debouncedCalculateCoin1Value.cancel();
        };
    }, [coin2, debouncedCalculateCoin1Value]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setTradeData({
            ...tradeData,
            [id]: value
        });
    };

    const toggleCoinShow = () => setShowCoinList(!showCoinList);

    return (
        <form>
            <div className="text-center mt-4 mb-4">
                <h5 className="font-bold">{`Buy ${defaultPair?.coin1.shortCode} with ${defaultPair?.coin2.shortCode}`}</h5>
            </div>
            <div className="d-flex align-items-center justify-content-between p-2 rounded border shadow-sm mb-4 main-coin" onClick={toggleCoinShow}>
                <div className="d-flex align-items-center justify-content-between w-100">
                    <img alt={defaultPair?.coin1.name} className="img-fluid coin-symbol" src={defaultPair?.coin1.icon} />
                    <p className="text-center mb-0" style={{ flex: 1 }}>{`${defaultPair?.coin1.shortCode}/${defaultPair?.coin2.shortCode}`}</p>
                    <img alt={defaultPair?.coin2.name} className="img-fluid coin-symbol" src={defaultPair?.coin2.icon} />
                </div>
                <div className="ps-3">
                    <div className="down-arrow"></div>
                </div>
            </div>
            <CoinList show={showCoinList} closeCoinList={() => setShowCoinList(false)} />
            <div className="d-flex align-items-center mb-4">
                <div className="d-none d-sm-block me-3">
                    <img alt={defaultPair?.coin1.name} className="img-fluid coin-symbol" src={defaultPair?.coin1.icon} />
                </div>
                <div className="flex-grow-1">
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id='coin1'
                            name='coin1'
                            placeholder="0"
                            value={coin1}
                            onChange={handleInputChange}
                            step="any" // Allows input of decimal values
                        />
                        <span className="input-group-text">{defaultPair?.coin1.shortCode}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center mb-4">
                <div className="d-none d-sm-block me-3">
                    <img alt={defaultPair?.coin2.name} className="img-fluid coin-symbol" src={defaultPair?.coin2.icon} />
                </div>
                <div className="flex-grow-1">
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id='coin2'
                            name='coin2'
                            placeholder="0"
                            value={coin2}
                            onChange={handleInputChange}
                            step="any" // Allows input of decimal values
                        />
                        <span className="input-group-text">{defaultPair?.coin2.shortCode}</span>
                    </div>
                </div>
            </div>
            <div>
                <button className="btn btn-success w-100" disabled>Get Quote</button>
            </div>
        </form>
    );
}
