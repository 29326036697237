import React from 'react'

export default function Remittance() {
    return (
        <div className='container'>
            <div className="row align-items-center">
                <div className="col-7 py-5 d-none d-md-flex align-items-center justify-content-center">
                    <img src="/assets/images/world-map.svg" alt="world map" style={{ minHeight: '300px' }} />
                </div>
                <div className="col-12 col-md-5  py-5">
                    <h2 className='mb-3'>Remittance</h2>
                    <p>We facilitate cross-border transactions across the globe to any jurisdiction in our ever expanding network. Through leveraging traditional payment infrastructure via our full-service FX solution and combining this with blockchain ledger technology we are able to significantly improve the efficiency and decrease the latency involved in remitting funds across borders. What is more; you get access to unmatched liquidity on African fiat currency pairs. Welcome to the future of FX, trade financing and international trade.</p>
                    <div className="d-flex">
                        <button className='btn btn-secondary rounded-30 me-2 btn-lg'>Get Started</button>
                        <button className="btn btn-outline-secondary btn-lg rounded-30">Contact us</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
