import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import BuyForm from './BuyForm';
import SellForm from './SellForm';

export default function TradeBuySell() {

    return (
        <div>
            <Tabs defaultActiveKey="buy" id="trade-tabs" className="mb-3 nav-justified">
                <Tab eventKey="buy" title="Buy">
                    <BuyForm />
                </Tab>
                <Tab eventKey="sell" title="Sell">
                    <SellForm />
                </Tab>
            </Tabs>
        </div>
    );
}
