import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import userSlice from './userSlice';
import { combineReducers } from '@reduxjs/toolkit';
import coinSlice from './coinSlice';

const encryptor = encryptTransform({
    secretKey: process.env.REACT_APP_REDUX_STORAGE_SECRET_KEY,
    onError: function (error) {
        console.log(error)
    },
});

const myReducers = combineReducers({
    user: userSlice,
    coin: coinSlice
});

const persistConfig = {
    key: 'root',
    storage: storage,
    timeout: null,
    transforms: [encryptor]
};

const persistedReducer = persistReducer(persistConfig, myReducers);

const isDevelopment = process.env.NODE_ENV === 'development';
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const ENABLE_REDUX_DEV_TOOLS = isDevelopment && isLocalhost;

export const store = configureStore({
    reducer: persistedReducer,
    devTools: ENABLE_REDUX_DEV_TOOLS,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            thunk: {
                // extraArgument: myCustomApiService
            }
        })
});

export const persistor = persistStore(store);
