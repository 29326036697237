import React from 'react'
import Banner from '../../components/Home/Banner'
import Partners from '../../components/Home/Partners'
import About from '../../components/Home/About'
import Features from '../../components/Home/Features'
import Services from '../../components/Home/Services'
import Blogs from '../../components/Home/Blogs'
import Testimonials from '../../components/Home/Testimonials'
import Faqs from '../../components/Home/Faqs'
import CTA from '../../components/Home/CTA'
import HowTo from '../../components/Home/HowTo'

export default function Home() {
    return (
        <div id='home'>
            <Banner />
            <Partners />
            <HowTo />
            <About />
            <Features />
            <Services />
            <Blogs />
            <Testimonials />
            <Faqs />
            <CTA />
        </div>
    )
}
