import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setIsLogged, setUserDetails } from '../../redux/userSlice';

export default function SignIn() {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        let formErrors = {};
        //eslint-disable-next-line
        if (!formData.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            formErrors.email = "Invalid email format.";
        }

        if (formData.password.length < 6) {
            formErrors.password = "Password must be at least 6 characters long.";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, formData, {
                    withCredentials: true,
                });

                if (response.status === 200) {
                    const user = response.data;
                    setSuccessMessage('Login successful! Redirecting to dashboard...');
                    setTimeout(() => {
                        dispatch(setIsLogged(true));
                        dispatch(setUserDetails(user));

                        // Check the user's role and navigate accordingly
                        if (user.role === 'admin') {
                            navigate('/admin/dashboard');
                        } else {
                            navigate('/client/dashboard');
                        }
                    }, 3000);
                }
            } catch (error) {
                if (error.response?.status === 403) {
                    setApiError("Unfortunately, you can't login until you verify the email we sent to you. Please check your spam folder from support@xenly.com.");
                } else {
                    setApiError(error.response?.data?.message || 'Invalid credentials. Please try again.');
                }
            }
        }
    };

    return (
        <section className="account padding-top padding-bottom sec-bg-color2">
            <div className="container">
                <div className="account__wrapper" data-aos="fade-up" data-aos-duration="800">
                    <div className="row g-4">
                        <div className="col-lg-12">
                            <div className="account__content account__content--style1">
                                <div className="account__header">
                                    <h2>Welcome back!</h2>
                                    <p>Hey there! Ready to log in? Just enter your email and password below and you'll be back in action in no time. Let's go!</p>
                                </div>

                                {apiError && <div className="alert alert-danger">{apiError}</div>}
                                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                <form className="account__form needs-validation" onSubmit={handleSubmit} noValidate>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <div>
                                                <label htmlFor="email" className="form-label">Email</label>
                                                <input
                                                    type="email"
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter your email"
                                                    required
                                                />
                                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-pass">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                    id="password"
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    placeholder="Password"
                                                    required
                                                />
                                                <button type="button" className="form-pass__toggle" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                </button>
                                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="account__check">
                                        <div className="account__check-remember">
                                            <input type="checkbox" className="form-check-input" value="" id="terms-check" />
                                            <label htmlFor="terms-check" className="form-check-label">
                                                Remember me
                                            </label>
                                        </div>
                                        <div className="account__check-forgot">
                                            <Link to="/forgot">Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <button type="submit" className="trk-btn trk-btn--border trk-btn--primary d-block mt-4">Sign in</button>
                                </form>
                                <div className="account__switch">
                                    <p>Don't have an account? <Link to="/signup">Sign up</Link></p>
                                    <p>Resend Email <Link to="/resend-verify">Verification Link</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="account__shape">
                <span className="account__shape-item account__shape-item--1">
                    <img src="/assets/images/others/4.png" alt="shape-icon" />
                </span>
            </div>
        </section>
    );
}