import React from 'react'

export default function Broker() {
    return (
        <div className='container'>
            <div className="row align-items-center">
                <div className="col-6 py-5 d-none d-md-flex align-items-center justify-content-center">
                    <img src="/assets/images/become-broker.svg" alt="become broker" style={{ maxHeight: '300px' }} />
                </div>
                <div className="col-12 col-md-6 py-5">
                    <h2 className="mb-3">Join XENLY as a Broker</h2>
                    <p>Ready to handle all your clients' trades with ease? XENLY provides a complete digital asset brokerage solution.</p>
                    <p>You can either fully manage client accounts or offer them partial access.</p>
                    <p>Effortlessly allocate trading profits and keep track of each client’s status.</p>
                    <p>Benefit from a dedicated support team focused on keeping your clients satisfied at every step.</p>
                    <div className="d-flex">
                        <button className='btn btn-secondary rounded-30 me-2 btn-lg'>Get Started</button>
                        <button className="btn btn-outline-secondary btn-lg rounded-30">Contact us</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
